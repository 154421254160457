import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

let ChangePasswordForm = (props) => {
    const { handleSubmit, pristine, submitting } = props;
    const { t} = useTranslation(['webapp']);

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: '100%', width: '50rem' }}>
            <Field
                component={RenderField}
                type="password"
                name="old_password"
                placeholder={t('settings.old_password')}
                label={t('settings.old_password')}
                required
            />

            <Field
                component={RenderField}
                type="password"
                name="new_password"
                placeholder={t('settings.new_password')}
                label={t('settings.new_password')}
                required
            />
            <Field
                component={RenderField}
                type="password"
                name="confirm_new_password"
                placeholder={t('settings.confirm_password')}
                label={t('settings.confirm_password')}
                required
            />
            <Field
                component="input"
                type="hidden"
                name="access_token"
                style={{ display: 'none' }}
            />
            <input
                type="submit"
                value={t('settings.change_pass_caps')}
                disabled={pristine || submitting}
            />
        </form>
    );
};

const RenderField = ({ input, label, type, meta: { touched, error }}) => {
    const {t} = useTranslation(['webapp'])
    return (
        <div className="form-group">
            <div>
                <input {...input} placeholder={label} type={type} />
                {touched && error && <span className="input-error">{t(`settings.${error}`)}</span>}
            </div>
        </div>
    );
};

const validate = (values) => {
    const errors = {};
    if (!values.old_password) {
        errors.old_password = 'old_pass_error';
    }

    if (!values.new_password) {
        errors.new_password = "new_pass_error";
    } else if (values.new_password.length < 6) {
        errors.new_password = "password_length_error";
    } else if (values.new_password !== values.confirm_new_password) {
        errors.confirm_new_password = "password_match_error";
        errors.new_password = "password_match_error";
    }

    return errors;
};

ChangePasswordForm = reduxForm({
    form: 'changePasswordForm',
    validate
})(ChangePasswordForm);

ChangePasswordForm = connect((state) => ({
    initialValues: {
        access_token: state.session.user.credentials
            ? state.session.user.credentials.accessToken
            : ''
    },
    enableReinitialize: true
}))(ChangePasswordForm);

export default ChangePasswordForm;
