import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from '../../actions/languages';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        document.getElementById('root').dir = i18n.dir();
    }, [i18n.language]);

    const languageSelect = languages.map((language) => {
        return (
            <option disabled={language.id == i18n.language} key={language.id} value={language.id}>
                {language.title}
            </option>
        );
    });

    return (
        <div className={`languageContainer ${i18n.language === 'en' ? 'right' : 'left'}`}>
            <i className="fas fa-globe-africa lang__icon"></i>
            <select
                value={i18n.language === 'ar' ? 'ar' : 'en'}
                onChange={(e) => {
                    i18n.changeLanguage(e.target.value);
                    if (localStorage.getItem('isTourOpen') === 'true') {
                        window.location.reload();
                    }
                }}
            >
                {languageSelect}
            </select>
        </div>
    );
};

export default LanguageSwitcher;
