import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { reset } from 'redux-form';
import { clearUserProfile } from '../actions/user';
import queryString from 'querystring';
import LOGO from '../img/RH_new_Logo.svg';
import SignUpForm from '../components/forms/SignUpForm';
import UtilityService from '../services/utilityService';
import LoadingScreen from '../components/LoadingScreen';
import userService from '../services/userService';
import providerService from '../services/providerService';
import { useTranslation, withTranslation } from 'react-i18next';

class SignUpContainer extends React.Component {
    constructor(props) {
        super(props);
        //check match for params, if exists get enrollee type and/or hash
        //check props for params, if exists get enrollee type and accessToken
        const { location } = props;
        const hash = queryString.parse(location.search.substr(1)).hash;

        this.state = {
            isLoading: true,
            formData: {
                user: {
                    hash: hash || null,
                    accessToken: this.props.location.state ? this.props.location.state.at : null
                },
                governorates: []
            },
            lang: this.props.i18n.language,
            message: null,
            error: null,
            successful: false
        };
    }

    componentDidMount() {
        const { user } = this.state.formData;

        Promise.all([
            UtilityService.getUtilities(),
            userService.unhashParams(user.hash),
            providerService.getProviders(user.accessToken),
            userService.getGovernorates(this.props.i18n.language === 'ar' ? 'translated' : '')
        ]).then(
            (response) => {
                this.setState({
                    isLoading: false,
                    formData: {
                        user: Object.assign({}, user, { ...response[1], enrolleeType: 1 }),
                        utils: Object.assign({}, response[0], {
                            providers: response[2]
                        }),
                        governorates: response[3]
                    },
                    error: null
                });
            },
            (e) => {
                if (e.response && e.response.status === 403) {
                    window.location = '/login';
                } else {
                    this.setError({
                        message: e.response.data.message || 'An Error Occurred',
                        status: e.response.status,
                        code: e.response.status
                    });
                }
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.lang !== this.props.i18n.language) {
            userService
                .getGovernorates(this.props.i18n.language === 'ar' ? 'translated' : '')
                .then((response) => {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            governorates: response
                        }
                    });
                });

            this.setState({
                lang: this.props.i18n.language
            });
        }
    }

    setError = (error, abort) => {
        this.setState({
            isLoading: false,
            abort,
            error
        });
    };

    scrollToError = () => {
        this.errorHolder.scrollIntoView({ behaviour: 'smooth' });
    };

    onSubmitForm = (values, dispatch) => {
        const {
            location: { state }
        } = this.props;

        this.setState({
            isLoading: true
        });

        if (state && state.subscriptionId) {
            values.subscription_id = state.subscriptionId;
            values.product_id = state.productId;
            values.enrollee_type = 2;
        }

        userService
            .register({
                ...values
            })
            .then(
                (message) => {
                    //reset sign up form on successful submission
                    dispatch(reset('signUpForm'));
                    dispatch(clearUserProfile());
                    this.setState({
                        error: null,
                        message: message,
                        successful: true,
                        abort: false,
                        isLoading: false
                    });
                },
                (e) => {
                    this.setError({
                        message: e.response.data.message,
                        code: e.status,
                        isLoading: false
                    });
                    this.scrollToError();
                }
            );
    };

    render() {
        const { isLoading, formData, error, abort, successful } = this.state;
        const translatedErr = error && getTranslatedErrorMessage(error.message, this.props.t);

        if (isLoading)
            return (
                <SignUpFormHolder>
                    <LoadingScreen />
                </SignUpFormHolder>
            );

        if (!isLoading && abort)
            return (
                <SignUpFormHolder>
                    <div
                        style={{
                            padding: '10px',
                            margin: '10px',
                            backgroundColor: 'red',
                            color: 'white'
                        }}
                    >
                        <h3>
                            {this.props.t('signUp.regErrorText')}{' '}
                            <a
                                style={{ color: 'white' }}
                                href="mailto:helloegypt@getreliancehealth.com?Subject=Enrollee Sign Up Help [from web app]"
                            >
                                {this.props.t('preSignUp.email')}
                            </a>
                            .
                        </h3>
                    </div>
                </SignUpFormHolder>
            );

        if (!isLoading && successful)
            return (
                <SignUpFormHolder>
                    <div
                        style={{
                            padding: '10px',
                            margin: '10px',
                            backgroundColor: '#fffffa',
                            color: '#111'
                        }}
                    >
                        <div>
                            <h3>{this.props.t('signUp.congratulations')}</h3>
                            <p>
                                {`${
                                    formData.user.accessToken
                                        ? this.props.t('signUp.yourFamily')
                                        : this.props.t('signUp.youHave')
                                }`}{' '}
                                {this.props.t('signUp.successMsg')}
                                <br />
                                <br />
                                {this.props.t('signUp.successText')}
                                <br />
                                <br />
                                {this.props.t('signUp.help')}
                                <br />
                                {this.props.t('signUp.cEmail')}:{' '}
                                <a href="mailto:helloegypt@getreliancehealth.com?Subject=Enrollee Enquiry [from web app]">
                                    {this.props.t('preSignUp.email')}
                                </a>
                                <br />
                                {this.props.t('signUp.cPhone')}: <a href="tel:17008">17008</a>
                                <br />
                                {this.props.t('signUp.cWhatsapp')}:{' '}
                                <a href="tel:+201119952208">{this.props.t('preSignUp.whatsapp')}</a>
                                <br />
                                {this.props.t('signUp.cFacebook')}:{' '}
                                <a href="https://www.facebook.com/Reliancehealtheg">
                                    {this.props.t('preSignUp.facebook')}
                                </a>
                                <br />
                                <br />
                                {this.props.t('signUp.thankYou')}
                            </p>
                            <br />
                            <br />
                            {formData.user.accessToken ? (
                                <div style={{ fontSize: '22px' }}>
                                    <div style={{ float: 'left' }}>
                                        <Link to="/profile">{this.props.t('signUp.goBack')}</Link>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <button
                                            className="button_link"
                                            onClick={() => window.location.reload()}
                                        >
                                            {this.props.t('signUp.addDependant')}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        fontSize: '20px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div style={{ marginBottom: '5px' }}>
                                        <span>
                                            {this.props.t('signUp.downloadApp')} (
                                            <a href="https://play.google.com/store/apps/details?id=com.reliancehmo.app.eg">
                                                Android
                                            </a>
                                            {' / '}
                                            <a href="https://apps.apple.com/eg/app/reliance-care-egypt/id6443599463">
                                                IOS
                                            </a>
                                            )
                                        </span>
                                    </div>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <span>Login to Dashboard</span>
                                        <a href="/login">{this.props.t('signUp.login')}</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </SignUpFormHolder>
            );

        return (
            <SignUpFormHolder user={formData.user}>
                {!isLoading && error && (
                    <div
                        ref={(el) => (this.errorHolder = el)}
                        style={{
                            padding: '10px',
                            margin: '10px',
                            backgroundColor: 'red',
                            color: 'white',
                            visibility: !isLoading && error ? 'visible' : 'hidden'
                        }}
                    >
                        <h3>{translatedErr}</h3>
                    </div>
                )}

                <SignUpForm
                    data={formData.utils}
                    user={formData.user}
                    governorates={formData.governorates}
                    onError={this.setError}
                    onSubmit={this.onSubmitForm}
                />
            </SignUpFormHolder>
        );
    }
}

const SignUpContainerWithTranslation = withTranslation(['signup'])(SignUpContainer);
export default withRouter(SignUpContainerWithTranslation);

export const SignUpFormHolder = (props) => {
    const { t } = useTranslation(['signup']);

    return (
        <div className="container">
            <div className="signup">
                <div className="signup__header">
                    <img src={LOGO} alt="logo" />
                    <p>{t('signUp.pageHead')}</p>
                </div>
                <div className="dashboard-card signup__form">{props.children}</div>
            </div>
        </div>
    );
};

function getTranslatedErrorMessage(error, t) {
    if (error.includes('sex')) {
        return t('signUp.genderErr');
    } else if (error.includes('date')) {
        return t('signUp.dobErr');
    } else if (error.includes('picture')) {
        return t('signUp.photoErr');
    } else if (error.includes('governorate')) {
        return t('signUp.governorateErr');
    } else if (error.includes('Token') || error.includes('invoice')) {
        return t('signUp.errorAccessToken');
    } else {
        return `${t('signUp.regErrorText')} ${t('preSignUp.email')}`;
    }
}
