/**
 * Created by the.last.mayaki on 31/10/2017.
 */
import { PROVIDER_ACTION_TYPES } from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({isFetching:false, providers:[], error:null });
export default function providerReducer(state=initialState, action){
    switch (action.type){
        case PROVIDER_ACTION_TYPES.GET_PROVIDERS:
            return Immutable(state).merge({
                isFetching: true
            });
        case PROVIDER_ACTION_TYPES.GET_PROVIDERS_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                providers: action.data,
                error: null
            });
        case PROVIDER_ACTION_TYPES.GET_PROVIDERS_FAIL:
            return Immutable(state).merge({
                isFetching:false,
                error: action.data
            });
        default:
            return state;
    }
}