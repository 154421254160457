import React from 'react';
import moment from 'moment';
import { CONSULTATION_STATUS } from '../services/utilityService';
import { useTranslation, withTranslation } from 'react-i18next';

const getConsultationStatus = (status) => {
    switch (parseInt(status)) {
        case 1:
            return 'orange';
        case 2:
            return 'green';
        default:
            return 'grey';
    }
};

function ConsultationList({
    list,
    msgStats,
    endConsultationConfirmations,
    handleChange
}) {
    const { t, i18n } = useTranslation(['consultDoctor']);


    return list.map((consultation, index) => (
        <div
            className="convo-thread-wrap"
            key={index}
            onClick={() => handleChange(consultation.id)}
        >
            <div className="convo-thread-wrap__main">
                <div style={{ minWidth: '7rem' }}>
                    <img
                        className="chat-doctor__img"
                        src={
                            consultation.doctor
                                ? consultation.doctor.profile_picture
                                : 'https://via.placeholder.com/150'
                        }
                        alt={'Doctor'}
                    />
                    <i
                        className={`fas fa-circle ${getConsultationStatus(
                            consultation.consultation_status
                        )} convo-thread-wrap__message-status`}
                    ></i>
                </div>
                <div style={{ flexGrow: '1', marginRight: '2rem' }}>
                    <p className="dashboard-card__title-text" style={{ marginBottom: '0.5rem' }}>
                        {consultation.doctor
                            ? `Dr. ${consultation.doctor.last_name}${
                                  consultation.reason === null ? '' : ` - ${consultation.reason}`
                              }`
                            : t('noDocAssigned')}
                    </p>
                    {endConsultationConfirmations[consultation.id] ||
                    parseInt(consultation.consultation_status) ===
                        CONSULTATION_STATUS.closePending ? (
                        <p
                            className="dashboard-card__body-text"
                            style={{ marginBottom: '0.5rem', fontSize: '1.5rem' }}
                        >
                            <strong>{t('docEndConsultationConfirm')}</strong>
                        </p>
                    ) : (
                        <p
                            className="dashboard-card__body-text"
                            style={{ marginBottom: '0.5rem', fontSize: '1.5rem' }}
                        >
                            <strong>{t('last_message')}</strong>{' '}
                            {consultation.last_message
                                ? consultation.last_message.message
                                : t('noMessage')}
                        </p>
                    )}
                    <p
                        className="dashboard-card__body-text"
                        style={{ marginBottom: '0.5rem', fontSize: '1rem' }}
                    >
                        {moment(consultation?.last_message?.created_at).isSame(new Date(), 'day') ? moment(consultation?.last_message?.created_at).locale(i18n.language).format('HH:mm') : moment(consultation?.last_message?.created_at).locale(i18n.language).format('DD-MM-YYYY')}

                    </p>
                </div>

                {msgStats[consultation.id] && msgStats[consultation.id].length > 0 && (
                    <div className="chat-item__new-message-count">
                        {msgStats[consultation.id].length}
                    </div>
                )}

                {[3, 4, 5, 6].includes(consultation.consultation_status) &&
                    consultation?.doctor?.active_status !== 6 && (
                        <div>
                            <button
                                className="dashboard__primary-btn"
                                onClick={() => handleChange(consultation.id)}
                                disabled={consultation?.doctor?.active_status === 6}
                            >
                                {t('follow_up_consultation')}
                            </button>
                        </div>
                    )}
                <div>
                    <i className="fa fa-chevron-right" />
                </div>
            </div>
        </div>
    ));
}

const ConsultationListWithTranslations = withTranslation(['consultDoctor'])(ConsultationList)

export default ConsultationListWithTranslations;
