import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../boxes/Modal';
import googleLogo from '../../img/google-play-badge.png';
import Apple from '../../img/apple.png';
import DoctorIllustration from '../../img/Doctor_illustration.svg';
import { useTranslation, withTranslation } from 'react-i18next';

const Index = ({ title, isVisible, onClose }) => {
    const { t, i18n } = useTranslation(['consultDoctor']);
    return (
        <Modal
            title={title}
            onClose={onClose}
            open={isVisible}
            style={{
                fontWeight: 'bold',
                fontSize: '2rem',
                maxWidth: '40rem',
                textAlign: 'center',
                padding: 0,
                margin: 0
            }}
        >
            <div style={{ maxWidth: '40rem', fontSize: '1.5rem' }}>
                <div
                    style={{
                        display: 'flex',
                        padding: '1rem',
                        background: '#FFFFFA',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#E0F1FF',
                            fontSize: '1.1rem',
                            padding: '4px 8px 4px 8px',
                            marginBottom: 8,
                            fontWeight: 'bold'
                        }}
                    >
                        <p> &#x24D8; {t('note_only_on_mobile')}</p>
                    </div>
                    <div className="" style={{ textAlign: 'center' }}>
                        {' '}
                        {t('open_mobile_app_or_download')}
                    </div>

                    <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                        <img src={DoctorIllustration} alt="" />
                    </div>

                    <div
                        className=""
                        style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '2rem'
                        }}
                    >
                        {t('get_app')}
                    </div>
                </div>
                <p
                    style={{
                        fontSize: '1.3rem',
                        textAlign: 'center',
                        fontWeight: 'normal'
                    }}
                >
                    {t('you_can_login')}
                </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <div style={{ maxWidth: 100 }}>
                    <a href="https://apps.apple.com/eg/app/reliance-care-egypt/id6443599463?platform=iphone">
                        <img src={Apple} width={'100%'} alt="" />
                    </a>
                </div>
                <div className="" style={{ maxWidth: 130 }}>
                    <a href="https://play.google.com/store/apps/details?id=com.reliancehmo.app.eg&hl=en_GB&gl=US">
                        <img src={googleLogo} alt="" width={'100%'} />
                    </a>
                </div>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    onYes: PropTypes.instanceOf(Function),
    onClose: PropTypes.instanceOf(Function),
    title: PropTypes.string,
    content: PropTypes.string
};

export default withTranslation(['consultDoctor'])(Index);
