import React, { useState, useEffect } from 'react';
import tourDoctor from '../tours/tourDoctor';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import PopModal from './pop-modal/PopModal';
import { useTranslation } from 'react-i18next';

export default function InitialConsultDoctorCard({ history }) {
    const [isTourOpen, setTourOpen] = useState(false);
    const { t, i18n } = useTranslation(['consultDoctor']);

    // const openTour = () => {
    //     setTourOpen(true);
    // };

    // useEffect(() => {
    //     openTour();

    //     if (localStorage.getItem('isTourOpen') == null) {
    //         localStorage.setItem('isTourOpen', true);
    //         setTourOpen(false);
    //     } else {
    //         let isTourOpenSync = localStorage.getItem('isTourOpen');
    //         setTourOpen(JSON.parse(isTourOpenSync));
    //     }
    // }, []);

    // const closeTour = () => {
    //     setTourOpen(false);
    // };

    const disableBody = (target) => disableBodyScroll(target);
    const enableBody = (target) => enableBodyScroll(target);

    return (
        <div className="dashboard-card">
            {/* replaced the commented implementation with the modal temporarily for Egypt */}
            {/* <PopModal /> */}

            <div className="empty-state-wrap">
                <i className="fas fa-user-md" style={{ fontSize: '4rem' }} />
                <br />
                <br />
                <br />
                <p className="dashboard-card__title-text" style={{ textAlign: 'center' }}>
                    {t('not_yet_consulted')}
                    <br />
                </p>
                <div>
                    <button
                        onClick={() => history.push('/consultations/new')}
                        className="dashboard__primary-btn dashboard-control__button"
                        style={{ width: 'auto' }}
                    >
                        {t('consult_doctor')}
                    </button>
                </div>
            </div>
        </div>
    );
}
