import React from 'react';
import SendMessageForm from './forms/SendMessageForm';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
const BUBBLE_HEIGHT_THRESHOLD = 100;

class MChatPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewMessageButton: false
        };
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    renderChatView = (message, index, arr, cb) => {
        if (index > 0) {
            let m1 = moment(arr[index - 1].created_at).startOf('day');
            let m2 = moment(message.created_at).startOf('day');
            let sameDay = m1.isSame(m2, 'day');
            if (!sameDay) {
                return cb(message, true);
            } else {
                return cb(message, false);
            }
        } else {
            return cb(message, true);
        }
    };

    scrollToBottom = (cb) => {
        this.messagesEnd.scrollIntoView({ behaviour: 'smooth' });
        if (cb) {
            cb();
        }
    };

    shouldShowNewMessageButton = () => {
        const chatLayout = document.getElementsByClassName('chat-section')[0];
        console.log(
            'scrollTop=>' + chatLayout.scrollTop,
            'offsetHeight=>' + chatLayout.offsetHeight,
            'scrollHeight=>' + chatLayout.scrollHeight
        );
        if (
            chatLayout &&
            chatLayout.scrollTop + chatLayout.offsetHeight + BUBBLE_HEIGHT_THRESHOLD * 2 <
                chatLayout.scrollHeight
        ) {
            this.toggleNewMessageButton(true);
        } else {
            this.scrollToBottom();
        }
    };

    toggleNewMessageButton = (showNewMessageButton) => {
        this.setState({
            showNewMessageButton
        });
    };

    render() {
        const { showNewMessageButton } = this.state;
        const {
            messages,
            renderChat,
            onSubmitMessage,
            data,
            consultationStatus,
            setMessageInputRef
        } = this.props;
        return (
            <div className="chat-wrapper dashboard-card">
                <div className="chat-section">
                    {messages && messages.length > 0 ? (
                        messages.map((message, index, arr) =>
                            this.renderChatView(message, index, arr, renderChat)
                        )
                    ) : (
                        <h1
                            style={{
                                textAlign: 'center',
                                width: '100%',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: '#c5c4c4'
                            }}
                        >
                            {this.props.t('no_messages_message')}
                        </h1>
                    )}
                    <div style={{ visibility: 'hidden' }} ref={(el) => (this.messagesEnd = el)}>
                        *
                    </div>
                </div>
                <div style={{ position: 'relative' }}>
                    {showNewMessageButton && (
                        <div
                            className="feedback_scroll_btn"
                            style={{ fontSize: '12px', borderRadius: '1rem' }}
                            onClick={() => this.scrollToBottom(this.toggleNewMessageButton(false))}
                        >
                            {this.props.t('new_message')}
                        </div>
                    )}
                    <SendMessageForm
                        onSubmit={onSubmitMessage}
                        data={data}
                        consultationStatus={consultationStatus}
                        setMessageInputRef={setMessageInputRef}
                    />
                </div>
            </div>
        );
    }
}

const MChatPageWithTranslation = withTranslation(['consultDoctor'], { withRef: true })(MChatPage);
export default MChatPageWithTranslation;
