import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './customDateTimePicker.css';
import moment from 'moment';
import 'moment/locale/ar';

let oneDay = 60 * 60 * 24 * 1000;
let todayTimestamp =
    Date.now() - (Date.now() % oneDay) + new Date().getTimezoneOffset() * 1000 * 60;

export default class CustomDatePicker extends Component {
    state = {
        getMonthDetails: []
    };

    constructor() {
        super();
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        const day = date.getDate();
        this.state = {
            dateObj: date,
            day,
            year,
            month,
            selectedDay: todayTimestamp,
            monthDetails: [],
            showMonths: false,
            monthList: '',
            showYears: false,
            yearList: '',
            hour: 1,
            minute: 0,
            timeType: { actual: '', display: '' },
            dateTimeSelected: false,
            selectedDateTime: {
                minute: 0,
                hour: 1,
                day,
                month,
                year
            },
            showDatePicker: false
        };
    }

    handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                visible: false
            });
            this.setState({ ...this.state, showDatePicker: false });
        }
    };

    componentDidMount() {
        const date = this.state.dateObj;
        const year = date.getFullYear();
        const month = date.getMonth();
        this.setState({
            ...this.state,
            monthDetails: this.getMonthDetails(year, month),
            timeType: { actual: 'AM', display: this.props.translationObjectNotation.time.AM }
        });

        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    componentDidUpdate(prevProps) {
        if (this.state.dateTimeSelected) {
            this.props.onChange({
                ...this.state.selectedDateTime,
                month: this.state.selectedDateTime.month + 1,
                timeType: this.state.timeType.actual
            });
        }

        if (prevProps.locale !== this.props.locale) {
            this.setState({
                ...this.state,
                showMonths: false,
                timeType: { actual: 'AM', display: this.props.translationObjectNotation.time.AM }
            });
        }
    }

    showDatePicker = () => {
        this.setState({
            ...this.state,
            showDatePicker: !this.state.showDatePicker
        });
    };

    getDayFromProps = () => {
        return this.props.translationObjectNotation.day;
    };

    daysMap = () => {
        const { Su, Mo, Tu, We, Th, Fr, Sa } = this.getDayFromProps();
        return [Su, Mo, Tu, We, Th, Fr, Sa];
        // 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    };

    getFullMonthFromProps = () => this.props.translationObjectNotation.fullMonth;

    monthMap = () => {
        const {
            January,
            February,
            March,
            April,
            May,
            June,
            July,
            August,
            September,
            October,
            November,
            December
        } = this.getFullMonthFromProps();
        return [
            January,
            February,
            March,
            April,
            May,
            June,
            July,
            August,
            September,
            October,
            November,
            December
        ];
    };

    getDayDetails = (args) => {
        let date = args.index - args.firstDay;
        let day = args.index % 7;
        let prevMonth = args.month - 1;
        let prevYear = args.year;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
        let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
        let timestamp = new Date(args.year, args.month, _date).getTime();
        return {
            date: _date,
            day,
            month,
            timestamp,
            dayString: this.daysMap()[day],
            monthOfDay: args.month,
            yearOfDay: args.year
        };
    };

    getNumberOfDays = (year, month) => {
        return 40 - new Date(year, month, 40).getDate();
    };

    getMonthDetails = (year, month) => {
        let firstDay = new Date(year, month).getDay();
        let numberOfDays = this.getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0;
        let cols = 7;

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                currentDay = this.getDayDetails({
                    index,
                    numberOfDays,
                    firstDay,
                    year,
                    month
                });
                monthArray.push(currentDay);
                index++;
            }
        }
        return monthArray;
    };

    isCurrentDay = (day) => {
        return day.timestamp === todayTimestamp;
    };

    isSelectedDay = (day) => {
        return day.timestamp === this.state.selectedDay;
    };

    getMonthStr = (month) => this.monthMap()[Math.max(Math.min(11, month), 0)] || 'Month';

    getDateStringFromTimestamp = (timestamp) => {
        let dateObject = new Date(timestamp);
        let month = dateObject.getMonth() + 1;
        let date = dateObject.getDate();
        return (
            dateObject.getFullYear() +
            '-' +
            (month < 10 ? '0' + month : month) +
            '-' +
            (date < 10 ? '0' + date : date)
        );
    };

    setDate = (dateData) => {
        let selectedDay = new Date(dateData.year, dateData.month - 1, dateData.date).getTime();
        this.setState({ selectedDay });
    };

    onDateClick = (day) => {
        const { month, year, hour, minute } = this.state;
        this.setState({
            selectedDay: day.timestamp,
            day: day.date,
            dateTimeSelected: true,
            selectedDateTime: {
                day: day.date,
                month: month + 1,
                year,
                hour,
                minute
            },
            showDatePicker: false
        });
    };

    setYear = (offset) => {
        let year = this.state.year + offset;
        let month = this.state.month;
        this.setState({
            ...this.state,
            year,
            monthDetails: this.getMonthDetails(year, month)
        });
    };

    setMonth = (offset) => {
        let year = this.state.year;
        let month = this.state.month + offset;
        if (month === -1) {
            month = 11;
            year--;
        } else if (month === 12) {
            month = 0;
            year++;
        }
        this.setState({
            year,
            month,
            monthDetails: this.getMonthDetails(year, month)
        });
    };

    handleHourChange = (actionType, factor = 1) => {
        if (actionType === 'increment') {
            this.state.hour >= 12
                ? this.setState({
                      ...this.state,
                      hour: 1,
                      selectedDateTime: { ...this.state.selectedDateTime, hour: 1 },
                      dateTimeSelected: true
                  })
                : this.setState({
                      ...this.state,
                      hour: this.state.hour + factor,
                      selectedDateTime: {
                          ...this.state.selectedDateTime,
                          hour: this.state.hour + factor,
                          minute: this.state.minute
                      },
                      dateTimeSelected: true
                  });
        } else {
            this.state.hour <= 1
                ? this.setState({
                      ...this.state,
                      hour: 12,
                      selectedDateTime: { ...this.state.selectedDateTime, hour: 12 },
                      dateTimeSelected: true
                  })
                : this.setState({
                      ...this.state,
                      hour: this.state.hour - factor,
                      selectedDateTime: {
                          ...this.state.selectedDateTime,
                          hour: this.state.hour - factor
                      },
                      dateTimeSelected: true
                  });
        }
    };

    handleMinuteChange = (actionType, factor = 1) => {
        if (actionType === 'increment') {
            this.state.minute >= 59
                ? this.setState({
                      ...this.state,
                      minute: 0,
                      selectedDateTime: {
                          ...this.state.selectedDateTime,
                          minute: 0
                      },
                      dateTimeSelected: true
                  })
                : this.setState({
                      ...this.state,
                      minute: this.state.minute + factor,
                      selectedDateTime: {
                          ...this.state.selectedDateTime,
                          minute: this.state.minute + factor,
                          selectedDateTime: {
                              ...this.state.selectedDateTime,
                              minute: this.state.minute + factor
                          }
                      },
                      dateTimeSelected: true
                  });
        } else {
            this.state.minute <= 0
                ? this.setState({
                      ...this.state,
                      minute: 59,
                      selectedDateTime: {
                          ...this.state.selectedDateTime,
                          minute: 59
                      },
                      dateTimeSelected: true
                  })
                : this.setState({
                      ...this.state,
                      minute: this.state.minute - factor,
                      selectedDateTime: {
                          ...this.state.selectedDateTime,
                          minute: this.state.minute - factor
                      },
                      dateTimeSelected: true
                  });
        }
    };

    handleTimeTypeChange = () => {
        const { PM, AM } = this.props.translationObjectNotation.time;
        this.setState({
            ...this.state,
            dateTimeSelected: true,
            timeType:
                this.state.timeType.actual === 'AM'
                    ? {
                          actual: 'PM',
                          display: PM
                      }
                    : {
                          actual: 'AM',
                          display: AM
                      }
        });
    };

    changeYearList = (actionType) => {
        const yearList = [];
        if (actionType === 'left') {
            let count = 0;
            while (count < 12) {
                count++;
                yearList.push(this.state.yearLimit[0] - count);
            }
        } else {
            let count = 0;
            while (count < 12) {
                count++;
                yearList.unshift(this.state.yearLimit[1] + count);
            }
        }
        this.renderYear(yearList, false);
    };

    renderCalendar() {
        let days = this.state.monthDetails.map((day, index) => {
            return (
                <div
                    className={
                        'c-day-container ' +
                        (day.month !== 0 ||
                        this.state.dateObj.getFullYear() > day.yearOfDay ||
                        (this.state.dateObj.getFullYear() === day.yearOfDay &&
                            this.state.dateObj.getMonth() > day.monthOfDay) ||
                        (this.state.dateObj.getMonth() === day.monthOfDay &&
                            this.state.dateObj.getFullYear() === day.yearOfDay &&
                            day.date < this.state.dateObj.getDate())
                            ? ' disabled'
                            : '') +
                        (this.isCurrentDay(day) ? ' highlight' : '') +
                        (this.state.dateTimeSelected && this.isSelectedDay(day)
                            ? ' highlight-green'
                            : '')
                    }
                    key={index}
                >
                    <div className="cdc-day">
                        <span onClick={() => this.onDateClick(day)}>{day.date}</span>
                    </div>
                </div>
            );
        });

        const { Su, Mo, Tu, We, Th, Fr, Sa } = this.getDayFromProps();

        const daysMap = [Su, Mo, Tu, We, Th, Fr, Sa];

        return (
            <div className="c-container">
                <div className={`cc-head ${this.props.locale === 'ar' ? 'cc-head-arabic' : ''}`}>
                    {daysMap.map((d, i) => (
                        <div key={i} className="cch-name">
                            {d}
                        </div>
                    ))}
                </div>
                <div className={`cc-body cc-body-arabic`}>{days}</div>
            </div>
        );
    }

    mappedMonth = () => {
        const { Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec } =
            this.props.translationObjectNotation.shortMonth;
        const months = [
            { shortName: Jan, fullName: 'JANUARY', index: 0 },
            { shortName: Feb, fullName: 'FEBRUARY', index: 1 },
            { shortName: Mar, fullName: 'MARCH', index: 2 },
            { shortName: Apr, fullName: 'APRIL', index: 3 },
            { shortName: May, fullName: 'MAY', index: 4 },
            { shortName: Jun, fullName: 'JUNE', index: 5 },
            { shortName: Jul, fullName: 'JULY', index: 6 },
            { shortName: Aug, fullName: 'AUGUST', index: 7 },
            { shortName: Sep, fullName: 'SEPTEMBER', index: 8 },
            { shortName: Oct, fullName: 'OCTOBER', index: 9 },
            { shortName: Nov, fullName: 'NOVEMBER', index: 10 },
            { shortName: Dec, fullName: 'DECEMBER', index: 11 }
        ];

        return (
            <div className="c-month-container">
                <div
                    className={`cc-month-body ${
                        this.props.locale === 'ar' ? 'cc-month-body-arabic' : ''
                    }`}
                >
                    {months.map((month) => (
                        <button
                            className={`cc-month-item ${
                                this.state.dateTimeSelected &&
                                month.index + 1 === this.state.selectedDateTime.month
                                    ? 'highlight-selected'
                                    : ''
                            }`}
                            key={month.index}
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    month: month.index,
                                    showYears: false,
                                    showMonths: false,
                                    monthDetails: this.getMonthDetails(this.state.year, month.index)
                                });
                            }}
                        >
                            {month.shortName}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    renderMonth = () => {
        const mappedMonth = this.mappedMonth();

        this.setState({ ...this.state, showMonths: true, monthList: mappedMonth });
    };

    renderYear = (yearList = [], constructYearList = true) => {
        if (constructYearList) {
            let count = 0;

            while (count < 6) {
                yearList.unshift(this.state.year - count);
                count++;
            }

            count = 1;

            do {
                yearList.push(this.state.year + count);
                count++;
            } while (count < 7);
        }

        const mappedYear = (
            <div className="c-month-container">
                <div className="cc-month-body">
                    {yearList.map((year, i) => (
                        <button
                            className={`cc-month-item ${
                                this.state.dateTimeSelected &&
                                this.state.selectedDateTime.year === year
                                    ? 'highlight-selected'
                                    : ''
                            }`}
                            key={i}
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    year,
                                    showYears: false,
                                    showMonths: true,
                                    monthList: this.mappedMonth()
                                });
                            }}
                        >
                            {year}
                        </button>
                    ))}
                </div>
            </div>
        );

        this.setState({
            ...this.state,
            showYears: true,
            yearList: mappedYear,
            showMonths: false,
            monthList: '',
            yearLimit: !constructYearList
                ? [yearList[yearList.length - 1], yearList[0]]
                : [yearList[0], yearList[yearList.length - 1]]
        });
    };

    render() {
        const { minute, timeType, selectedDateTime, dateTimeSelected } = this.state;
        const { locale, placeholder } = this.props;
        const { month, day, year, hour, minute: selectedMinute } = selectedDateTime;
        return (
            <div className="MyDatePicker">
                <div className="mdp-input" onClick={() => this.showDatePicker()}>
                    <div
                        className={`date-time-input-button ${
                            !dateTimeSelected ? 'date-time-input-placeholder' : ''
                        }`}
                        tabIndex="0"
                    >
                        {dateTimeSelected ? (
                            <>
                                {`${moment(
                                    `${month}-${day}-${year} ${hour}:${selectedMinute}`,
                                    'M-D-YYYY h:mm'
                                )
                                    .locale(locale)
                                    .format('YYYY-MM-DD hh:mm')}

                                 ${timeType.display}`}
                            </>
                        ) : (
                            placeholder
                        )}
                    </div>
                </div>
                {this.state.showDatePicker && (
                    <div className={`mdtp-container ${locale === 'ar' ? 'transpose-left' : ''}`}>
                        <div className="mdp-container">
                            {this.state.showYearRange ? (
                                <div className="mdpc-body">{this.state.yearRanges}</div>
                            ) : this.state.showYears ? (
                                <>
                                    <div className="mdpc-head">
                                        <div className="mdpch-button">
                                            <div
                                                className={`mdpchb-inner ${
                                                    locale === 'ar' ? 'mdpchb-inner-arabic' : ''
                                                }`}
                                                onClick={() => this.changeYearList('left')}
                                            >
                                                <span className="mdpchbi-left-arrows"></span>
                                            </div>
                                        </div>
                                        <div
                                            className="mdpchc-month"
                                            dir={locale === 'ar' ? 'ltr' : ''}
                                        >
                                            {`${this.state.yearLimit[0]} - ${this.state.yearLimit[1]}`}
                                        </div>
                                        <div
                                            className="mdpch-button"
                                            onClick={() => this.changeYearList('right')}
                                        >
                                            <div
                                                className={`mdpchb-inner mdchb-inner-right-arrow ${
                                                    locale === 'ar' ? 'mdpchb-inner-arabic' : ''
                                                }`}
                                            >
                                                <span className="mdpchbi-right-arrows"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mdpc-body">{this.state.yearList}</div>
                                </>
                            ) : this.state.showMonths ? (
                                <>
                                    <div className="mdpc-head">
                                        <div className="mdpch-button">
                                            <div
                                                className={`mdpchb-inner ${
                                                    locale === 'ar' ? 'mdpchb-inner-arabic' : ''
                                                }`}
                                                onClick={() => this.setYear(-1)}
                                            >
                                                <span className="mdpchbi-left-arrows"></span>
                                            </div>
                                        </div>
                                        <div
                                            className="mdpchc-month"
                                            onClick={() => this.renderYear()}
                                        >
                                            {this.state.year}
                                        </div>
                                        <div
                                            className="mdpch-button"
                                            onClick={() => this.setYear(1)}
                                        >
                                            <div
                                                className={`mdpchb-inner mdchb-inner-right-arrow ${
                                                    locale === 'ar' ? 'mdpchb-inner-arabic' : ''
                                                }`}
                                            >
                                                <span className="mdpchbi-right-arrows"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mdpc-body">{this.state.monthList}</div>
                                </>
                            ) : (
                                <>
                                    <div className="mdpc-head">
                                        <div className="mdpch-button">
                                            <div
                                                className={`mdpchb-inner ${
                                                    locale === 'ar' ? 'mdpchb-inner-arabic' : ''
                                                }`}
                                                onClick={() => this.setMonth(-1)}
                                            >
                                                <span className="mdpchbi-left-arrow"></span>
                                            </div>
                                        </div>
                                        <div
                                            dir={locale === 'ar' ? 'ltr' : 'rtl'}
                                            className="mdpchc-month"
                                            onClick={this.renderMonth}
                                        >
                                            {locale === 'ar'
                                                ? ` ${this.state.year} ${this.getMonthStr(
                                                      this.state.month
                                                  )}`
                                                : `${this.getMonthStr(this.state.month)} ${
                                                      this.state.year
                                                  }`}
                                        </div>
                                        <div className="mdpch-button">
                                            <div
                                                className={`mdpchb-inner mdchb-inner-right-arrow ${
                                                    locale === 'ar' ? 'mdpchb-inner-arabic' : ''
                                                }`}
                                                onClick={() => this.setMonth(1)}
                                            >
                                                <span className="mdpchbi-right-arrow"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mdpc-body">{this.renderCalendar()}</div>
                                </>
                            )}
                        </div>
                        <div
                            className={`mtp-container ${
                                locale === 'ar' ? 'mtp-container-arabic' : ''
                            }`}
                        >
                            <div className="mtp-counter">
                                <i
                                    className="fas fa-chevron-up control-icons"
                                    onClick={() => this.handleHourChange('increment')}
                                ></i>
                                {this.state.hour < 10 ? `0${this.state.hour}` : this.state.hour}
                                <i
                                    className="fas fa-chevron-down control-icons"
                                    onClick={() => this.handleHourChange('decrement')}
                                ></i>
                            </div>
                            <div className="mtp-column">:</div>
                            <div className="mtp-counter">
                                <i
                                    className="fas fa-chevron-up control-icons"
                                    onClick={() => this.handleMinuteChange('increment')}
                                ></i>
                                {this.state.minute < 10 ? `0${this.state.minute}` : minute}
                                <i
                                    className="fas fa-chevron-down control-icons"
                                    onClick={() => this.handleMinuteChange('decrement')}
                                ></i>
                            </div>
                            <div className="mtp-column">:</div>
                            <div className="mtp-counter">
                                <i
                                    className="fas fa-chevron-up control-icons"
                                    onClick={this.handleTimeTypeChange}
                                ></i>
                                {this.state.timeType.display}
                                <i
                                    className="fas fa-chevron-down control-icons"
                                    onClick={this.handleTimeTypeChange}
                                ></i>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
