import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import LOGO from '../img/RH_new_Logo.svg';

class PageNotFound extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
    }

    render() {
        const { history } = this.props;

        return (
            <div className="container" style={{ paddingBottom: '50px' }}>
                <div style={{ paddingTop: '150px', textAlign: 'center' }}>
                    <Link to={'/'}>
                        <img src={LOGO} alt="Reliance HMO's Logo" className="logo__img" />
                    </Link>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '200px',
                        marginBottom: '10px',
                        color: '#c5c4c4'
                    }}
                >
                    {this.props.t('notFound.404')}
                </div>
                <div style={{ textAlign: 'center', fontSize: '16px' }}>
                    {this.props.t('notFound.oops')}
                    <br />
                    <br />
                    <Link to={'#'} onClick={history.goBack}>
                        {this.props.t('notFound.go_back')}
                    </Link>
                </div>
            </div>
        );
    }
}

const PageNotFoundWithTransitions = withTranslation(['webapp'])(PageNotFound);
export default withRouter(PageNotFoundWithTransitions);
