import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../boxes/Modal";
import { useTranslation, withTranslation } from 'react-i18next';
const DecisionModal = ({title, isVisible, onYes, content, onClose}) => {
  const {t} = useTranslation(['consultDoctor']);
  return (
    <Modal title={title} onClose={onClose} open={isVisible}>
      <div style={{maxWidth:"60rem"}}>
        <p style={{fontSize: "1.5rem"}}>{title}</p>
        <p style={{fontSize: "1.2rem"}}>{content}</p>
        <div style={{display: "flex", justifyContent: "flex-end",padding: "1rem",background: "#FFFFFA"}}>
          <button
            className="primary_button red"
            style={{background: "none", color:"#ab0404", border: "1px solid #ab0404"}}
            onClick={onYes}>
            {'  '}
            {t('yes')}
          </button>
          <button
            className="primary_button"
            onClick={onClose}>
            {'  '}
            {t('no')}
          </button>
        </div>
      </div>
    </Modal>
  )
};


DecisionModal.propTypes = {
  onYes: PropTypes.instanceOf(Function),
  onClose: PropTypes.instanceOf(Function),
  title: PropTypes.string,
  content: PropTypes.string
};

export default DecisionModal;