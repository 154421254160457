import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';
import * as userActions from '../actions/user';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import Tab from '../components/boxes/Tab';
import MainContent from '../components/boxes/MainContent';
import LoadingScreen from '../components/LoadingScreen';
import CenteredContentBlock from '../components/boxes/CenteredContentBlock';
import ButtonBar from '../components/boxes/ButtonBar';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import tourBenefits from '../components/tours/tourBenefits';
import { useTranslation, withTranslation } from 'react-i18next';

class BenefitsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            searchInput: '',
            isTourOpen: false,
            isShowingMore: false
        };
    }

    disableBody = (target) => disableBodyScroll(target);
    enableBody = (target) => enableBodyScroll(target);

    closeTour = () => {
        this.setState({ isTourOpen: false });
        localStorage.setItem('isTourOpen', false);
    };

    openTour = () => {
        this.setState({ isTourOpen: true });
    };

    componentDidMount() {
        const { dispatch, user } = this.props;
        dispatch(userActions.fetchBenefits(user.credentials.accessToken));
        //this.openTour()

        if (localStorage.getItem('isTourOpen') == null) {
            localStorage.setItem('isTourOpen', true);
            this.setState({ isTourOpen: true });
        } else {
            let isTourOpenSync = localStorage.getItem('isTourOpen');
            this.setState({ isTourOpen: JSON.parse(isTourOpenSync) });
        }
    }

    selectTab = (index) => {
        this.setState({
            selectedTab: index
        });
    };

    renderLoading() {
        return (
            <MainContent>
                <LoadingScreen />
            </MainContent>
        );
    }

    handleSearch = (event) => {
        this.setState({
            searchInput: event.target.value.substr(0, 10)
        });
    };

    renderContent() {
        const { benefits, user } = this.props;
        const { selectedTab, searchInput, isTourOpen } = this.state;

        let filteredBenefits = benefits;

        if (searchInput) {
            filteredBenefits = filteredBenefits.filter((benefit) => {
                return benefit.benefit_name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
            });
        }

        const availableNowBenefits = filteredBenefits.filter((benefit) => benefit.is_available);

        const availableLaterBenefits = filteredBenefits.filter((benefit) => !benefit.is_available);

        return (
            <MainContent>
                <PageHeader
                    title={this.props.t('my_benefits.benefit')}
                    searchBarPlaceholder={this.props.t('my_benefits.search_benefits')}
                    showSearchBar
                    handleSearch={this.handleSearch}
                    dataTut="step_benefit_search_tour"
                />

                <PageBody>
                    <ButtonBar
                        dataTut="step_secondary_care_tour"
                        other={{
                            name: this.props.t('my_benefits.access_secondary_care'),
                            func: () => this.props.history.push('/benefits/secondary-care')
                        }}
                    />
                    <div className="dashboard-card dashboard-table-wrap" data-tut="step_six_tour">
                        <div className="dashboard-table-overflow">
                            {user.package.toLowerCase() === 'retail' ? (
                                searchInput.length === 0 ? (
                                    <React.Fragment>
                                        <div
                                            className="dashboard-btn-section dashboard-tab"
                                            style={{ flexWrap: 'wrap' }}
                                        >
                                            <Tab
                                                title={`AVAILABLE NOW(${availableNowBenefits.length})`}
                                                index={0}
                                                isActive={selectedTab === 0}
                                                onClick={this.selectTab}
                                            />
                                            <Tab
                                                title={`AVAILABLE LATER(${availableLaterBenefits.length})`}
                                                index={1}
                                                isActive={selectedTab === 1}
                                                onClick={this.selectTab}
                                            />
                                        </div>
                                        <React.Fragment>
                                            {selectedTab === 0 && (
                                                <BenefitsTable benefits={availableNowBenefits} />
                                            )}
                                            {selectedTab === 1 && (
                                                <BenefitsTable benefits={availableLaterBenefits} />
                                            )}
                                        </React.Fragment>
                                    </React.Fragment>
                                ) : (
                                    <BenefitsTable benefits={filteredBenefits} />
                                )
                            ) : (
                                <BenefitsTable benefits={filteredBenefits} />
                            )}
                        </div>
                    </div>
                </PageBody>
                <Tour
                    onRequestClose={this.closeTour}
                    steps={tourBenefits(this.props.t, this.props.i18n)}
                    isOpen={isTourOpen}
                    maskClassName="mask"
                    className="helper"
                    rounded={12}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                />
            </MainContent>
        );
    }

    render() {
        const { benefits, isFetching } = this.props;

        if (!isFetching && !_.isEmpty(benefits)) return this.renderContent();

        return this.renderLoading();
    }
}

const BenefitsTable = (props) => {
    const { benefits, style } = props;
    const { t, i18n } = useTranslation(['webapp']);

    const toggleAlignmentBasedOnLaguageType = () => i18n.language.toLowerCase() === 'ar' ?  'text_right' : 'text_left';

    return benefits.length === 0 ? (
        <CenteredContentBlock>{t('my_benefits.no_result')}</CenteredContentBlock>
    ) : (
        <table className="dashboard-table" style={style}>
            <thead>
                <tr>
                    <th className={`dashboard-table-item dashboard-table-item__header ${toggleAlignmentBasedOnLaguageType()}`}>
                        {t('my_benefits.benefit')}
                    </th>
                    <th className={`dashboard-table-item dashboard-table-item__header ${toggleAlignmentBasedOnLaguageType()}`}>
                        {t('my_benefits.status')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {benefits.map((benefit, index) => (
                    <tr key={index}>
                        <td className={`dashboard-table-item dashboard-table-item__body ${toggleAlignmentBasedOnLaguageType()}`}>
                            {benefit.benefit_name}
                        </td>
                        <td className={`dashboard-table-item dashboard-table-item__body ${toggleAlignmentBasedOnLaguageType()}`}>
                            {benefit.benefit_value}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

function mapStateToProps(state) {
    let { isFetching, error } = state.user || {};
    let { benefits } = state.user.meta || {};
    return { benefits, isFetching, error };
}

const BenefitsContainerWithTranslation = withTranslation(['webapp', 'tour'])(BenefitsContainer);
export default withRouter(connect(mapStateToProps)(BenefitsContainerWithTranslation));
