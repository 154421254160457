import React from 'react';
import {Field,FieldArray,arrayPush,arrayRemove,formValueSelector,reduxForm, change, getFormValues} from 'redux-form';
import ImageInput from '../inputs/ImageInput';
import {connect} from 'react-redux';
import AttachmentModal from "../modals/AttachmentModal";
import { CONSULTATION_STATUS } from "../../services/utilityService";
import { withTranslation } from 'react-i18next';


const addImageButtonStyle = {
  width: "100%",
  borderRadius: "4px",
  padding: "5px",
  backgroundColor: "transparent",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "3rem",
  borderRight:"none",
  borderWidth:"2px",
  borderStyle:"inset",
  fontSize:"1.5rem",
  color:"#888787"
};

class SendMessageForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showAttachmentModal:false
    };
    this.messageInputRef = React.createRef();
    console.log("Props", props);
  }

  sendMessage = e => {

  };

  componentDidMount(){
    const {dispatch, data, setMessageInputRef} = this.props;
    if(this.messageInputRef.current) {
      setMessageInputRef(this.messageInputRef.current.getRenderedComponent())
    }
    dispatch(change("sendMessageForm","access_token",data.userToken));
    dispatch(change("sendMessageForm","consultationId",data.consultationId));
  };

  onAttachmentAdded = (attachment) => {
    const {dispatch} = this.props;
    dispatch(arrayPush("sendMessageForm","fileArray",attachment));
  };

  removeAttachment = (attachment) => {
    const {dispatch,fileArray} = this.props;
    const fileIndex = fileArray.indexOf(attachment);
    dispatch(arrayRemove("sendMessageForm","fileArray",fileIndex));
  };

  toggleAttachmentsModal = () => {
    this.setState({
      showAttachmentModal:!this.state.showAttachmentModal
    })
  };
  
  render(){
    const {handleSubmit, pristine, submitting, fileArray=[], consultationStatus, formValues} = this.props;
    const {showAttachmentModal} = this.state;

    return (
      <React.Fragment>
        {showAttachmentModal &&
          <AttachmentModal attachments={fileArray} canDelete onDelete={this.removeAttachment} onClose={this.toggleAttachmentsModal} />
        }
        {
          (parseInt(consultationStatus) === CONSULTATION_STATUS.active ||
          parseInt(consultationStatus) === CONSULTATION_STATUS.reopened) ?
          <form onSubmit={handleSubmit} className="chat-input chat-input-details">
              {fileArray.length > 0 &&
                <div className="attachment_count" style={{cursor: "pointer"}} onClick={this.toggleAttachmentsModal}>
                  <i className="fa fa-paperclip"/>
                  {`  ${fileArray.length} Attachment(s). Click to view.`}
                </div>
              }
            <div className="chat-input-wrapper">
              <ImageInput
                width={150}
                height={150}
                asButton
                buttonCaption=" "
                customClassName="chat-input__file-input"
                buttonStyle={addImageButtonStyle}
                onCropComplete={this.onAttachmentAdded}/>
              <Field
                ref={this.messageInputRef}
                autoComplete="off"
                type="text"
                name="question"
                component="input"
                placeholder={this.props.t('chat_doctor')}
                onKeyDown={this.sendMessage}
                withRef
              />
              <Field className="hidden" name="access_token" component="input" style={{display: "none"}}/>
              <FieldArray
                name="fileArray"
                component={renderFileArray}
                />
              <button
                type="submit"
                className="dashboard__primary-btn"
                disabled={pristine || submitting || !formValues.question}
              >
                {this.props.t('send')}
              </button>
            </div>
          </form> :
          <div className="chat-input" style={{display: "flex", fontSize: "1.6em", backgroundColor: "#333333", color: "#fff", justifyContent: "center"}}>
            <p>{parseInt(consultationStatus) === CONSULTATION_STATUS.pending ? this.props.t('waiting_for_doctor') : this.props.t('closed_consultations_disclaimer')}</p>
          </div>
        }
      </React.Fragment>
    )
  }
}

const renderFileArray = ({fields}) => {
  return (
    <div className="form_group" style={{display:"none"}}>
      {fields.map((field,index)=>(
        <Field key={index} component="input" name={`file_${index+1}`}/>
      ))}
    </div>
  )
};

SendMessageForm = reduxForm({
  form: 'sendMessageForm'
})(SendMessageForm);
const s = formValueSelector("sendMessageForm");
SendMessageForm = connect(state => ({
  formValues: getFormValues('sendMessageForm')(state),
  enableReinitialize: true,
  destroyOnUnmount: true,
  fileArray: s(state,"fileArray")
}))(SendMessageForm);

const SendMessageFormWithTranslation = withTranslation(['consultDoctor'])(SendMessageForm);

export default SendMessageFormWithTranslation;