import React from 'react';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next';

const TextData = (props) => {
  const { i18n } = useTranslation(['webapp']);
  return (
    <React.Fragment>
      <p> {props.message}</p>
      <div className="chat-item__message-time doctor-chat__time">
      {moment(props.created_at).locale(i18n.language).format('HH:mm')}
      </div>
    </React.Fragment> 
  )
}

export default TextData;

