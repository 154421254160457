import React from 'react';
import { connect } from 'react-redux';
import { DISPLAY_ACTION_TYPES } from '../../actions/actionTypes';
import { useTranslation } from 'react-i18next';

function PageHeader({
    title,
    showButton,
    onButtonClick,
    buttonTitle,
    showSearchBar,
    searchBarPlaceholder,
    handleSearch,
    dispatch,
    dataTut,
    ...props
}) {
    const toggleSideBar = () => {
        dispatch({ type: DISPLAY_ACTION_TYPES.TOGGLE_SIDEBAR });
    };

    const updateSearchInput = (event) => {
        return handleSearch(event);
    };
    const { i18n } = useTranslation();
    let why = true;

    return (
        <div className="top-right content">
            <div className="header">
                <div className="header__left">
                    <button className="toggle-nav" onClick={toggleSideBar}>
                        &#9776;&nbsp;
                    </button>
                </div>
                <div className="header__title">
                    <h2>{title}</h2>
                </div>
                <div className="header__right">
                    {showSearchBar && (
                        <div
                            className="header__search"
                            data-tut={dataTut}
                            style={
                                i18n.language === 'ar'
                                    ? { marginLeft: '110px' }
                                    : { marginRight: '110px' }
                            }
                        >
                            <input
                                type="text"
                                name="search-bar"
                                onChange={updateSearchInput}
                                placeholder={searchBarPlaceholder}
                            />
                        </div>
                    )}

                    {showButton && (
                        <div
                            style={
                                i18n.language === 'ar'
                                    ? { marginLeft: '100px' }
                                    : { marginRight: '100px' }
                            }
                        >
                            <button onClick={onButtonClick}>{buttonTitle}</button>
                        </div>
                    )}

                    {false && (
                        <div className="header__notif">
                            <button className="badge notif" data-badge="1" aria-hidden="true">
                                <i className="fa fa-bell fa-2" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default connect()(PageHeader);
