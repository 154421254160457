import axios from 'axios';
import { Subject, lastValueFrom } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { DAARA_ACCOUNT_SERVICE_URL, DAARA_COOKIE_NAME, DAARA_TOKEN_PREFIX } from './utilityService';

const axiosInterceptor = axios.create();

let isRefreshPending = false;
let refreshSubject = new Subject();

axiosInterceptor.interceptors.request.use(
    (config) => {
        const { url } = config;
        if (!url.includes('/refresh-token')) {
            const daara_token = localStorage.getItem(`${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`);
            config.headers.Authorization = 'Bearer ' + daara_token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401) {
            try {
                if (isRefreshPending) {
                    const accessToken = await lastValueFrom(
                        refreshSubject.pipe(
                            filter((access) => !!access),
                            take(1),
                            map((res) => res)
                        )
                    );
                    originalRequest.headers.Authorization = 'Bearer ' + accessToken;
                    return axiosInterceptor(originalRequest);
                }
                isRefreshPending = true;
                refreshSubject.next(null);
                const { data } = await refreshAccessToken();
                refreshSubject.next(data.access_token);
                isRefreshPending = false;
                localStorage.setItem(
                    `${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`,
                    data.access_token
                );

                originalRequest.headers.Authorization = 'Bearer ' + data.access_token;
                return axiosInterceptor(originalRequest);
            } catch (error) {
                localStorage.removeItem(`${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`);
                window.location.href = '/logout';
            }
        }
        return Promise.reject(error);
    }
);

async function refreshAccessToken() {
    return axios.get(`${DAARA_ACCOUNT_SERVICE_URL}/refresh-token`, {
        withCredentials: true
    });
}

export async function sendHttpGetRequest(url, config) {
    return axiosInterceptor.get(url, { ...config });
}

export async function sendHttpPutRequest(url, data, config) {
    return axiosInterceptor.put(url, data, { ...config });
}

export async function sendHttPostRequest(url, data, config) {
    return axiosInterceptor.post(url, data, { ...config });
}

export default axiosInterceptor;
