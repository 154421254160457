import React from 'react';

export default ({ message, img, footer, language }) => {
    return (
        <div className="" style={{ textAlign: 'center', display: 'grid' }}>
            <div
                className=""
                style={{
                    margin: 2,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    borderRadius: 15,
                    padding: 0
                }}
            >
                <img
                    src={img}
                    alt=""
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        padding: 10,
                        backgroundColor: '#107BC0',
                        borderTopLeftRadius: language == 'ar' ? 0 : 10,
                        borderTopRightRadius: language == 'ar' ? 10 : 0,
                        borderBottomRightRadius: language == 'ar' ? 10 : 0,
                        borderBottomLeftRadius: language == 'ar' ? 0 : 10
                    }}
                />

                <div
                    style={{
                        fontSize: '1.5rem',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        padding: 10,
                        marginRight: 0,
                        borderTopRightRadius: language == 'ar' ? 0 : 10,
                        borderBottomRightRadius: language == 'ar' ? 0 : 10,
                        borderTopLeftRadius: language == 'ar' ? 10 : 0,
                        borderBottomLeftRadius: language == 'ar' ? 10 : 0,

                        borderRight: '1.3px solid #107BC0',
                        backgroundColor: 'white',
                        border: '1px solid #107BC0'
                    }}
                >
                    {message}
                </div>
            </div>
            <div className="" style={{ fontWeight: 'lighter', color: 'gray', fontSize: '1.3rem' }}>
                {footer}
            </div>
        </div>
    );
};
