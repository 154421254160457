import React from 'react';
import queryString from 'querystring';
import LoginForm from '../components/forms/LoginForm';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import {Link,withRouter} from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import {connect} from 'react-redux';
import * as userActions from '../actions/user';

class LoginContainer extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            forgotPassword:false,
            referrer:queryString.parse(props.location.search.substr(1)).r
        }
    }

    renderLoading(){
        return (
            <LoadingScreen/>
        )
    };

    componentDidMount(){
      const {dispatch} = this.props;
      const {referrer} = this.state;
      dispatch(userActions.authUser(referrer));
    }

    toggleForm = (e) => {
      e.preventDefault();
      this.setState({
          forgotPassword:!this.state.forgotPassword
      });
    };


    onFormSubmit = (values,dispatch,props) => {
        const { forgotPassword } = this.state;
        if(forgotPassword){
            dispatch(userActions.resetPassword(values));
        }else{
            dispatch(userActions.loginUser(values, props.history));
        }
    };


    renderLogin = () => {
        const {isLoading, error} = this.props;
        const {forgotPassword} = this.state;
        return(
            <FormHolder message={error.message} isLoading={isLoading}>
                <LoginForm onSubmit={this.onFormSubmit}/>
                <PageExtras isForgotPassword={forgotPassword} toggleForm={this.toggleForm}/>
            </FormHolder>
        )
    };

    renderForgotPassword =() => {
        const {isLoading, error} = this.props;
        const {forgotPassword} = this.state;
        return (
            <FormHolder message={error.message} isLoading={isLoading}>
                <ForgotPasswordForm onSubmit={this.onFormSubmit}/>
                <PageExtras isForgotPassword={forgotPassword} toggleForm={this.toggleForm}/>
            </FormHolder>
        )
    };

    renderPasswordResetSuccessfulMessage = () => {
        return (
            <FormHolder>
                <MessageHolder>
                    <h3>Your password has been reset.</h3>
                    <p>
                        A default password has been sent to you via SMS/mail. Use this to login.
                        Remember to change the password once you're in.
                        <br/>
                        For enquiries, don't hesitate to send a mail to <a href="mailto:helloegypt@getreliancehealth.com?Subject=Enrollee Enquiry [from web app]">helloegypt@getreliancehealth.com</a>
                        <br/>
                        Thanks for choosing RelianceHMO.
                    </p>
                </MessageHolder>
            </FormHolder>
        )
    };

    render() {
        const {isLoading, checked, authenticated, recoverySuccessful} = this.props;

        const {forgotPassword, referrer} = this.state;

        if (isLoading) return this.renderLoading();

        if (checked && authenticated) {
          window.location.replace(referrer || "/");
          return null;
        }

        if (recoverySuccessful) return this.renderPasswordResetSuccessfulMessage();

        if (forgotPassword) return this.renderForgotPassword();

        return this.renderLogin();
    }
}



const FormHolder = (props) => {
    return (
        <div className="container">
            <div className="login_form">
                <div style={{padding:"10px",backgroundColor:"red",color:"white",visibility:(!props.isLoading && props.message)?"visible":"hidden"}}>
                    {props.message}
                </div>
                <div className="form_wrapper">

                    {props.children}

                </div>
            </div>
        </div>
    )
};


const MessageHolder = (props) => {
    return (
        <div style={{padding:"10px",margin:"10px",backgroundColor:"white",color:"#111"}}>
            <div>
                {props.children}
            </div>
        </div>
    )
};


const PageExtras = (props) => {
    return (
        <div>
            <p>
                {props.isForgotPassword ? "" : "Forgot Password?"}
                <span className="mini_cta">
                    <Link to={'#'} onClick={props.toggleForm}>
                        {props.isForgotPassword ? "Back" : "Click Here" }
                    </Link>
                </span>
            </p>

            <p className="text_left">Dont have a Plan?
                <span className="mini_cta">
                    <a href="mailto:helloegypt@getreliancehealth.com?subject=Request Quote"> Request Quote</a>
                </span>
            </p>
            <br/>
            <p className="text_left">
                Are you a provider trying to Log in?
                <span className="mini_cta">
                    <a href="https://provider.reliancehmo.com/login"> Click Here</a>
                </span>
            </p>
        </div>
    )
};


function mapStateToProps(state){
    const { isLoading, error, recoverySuccessful } = state.user||{};
    const { checked, authenticated } = state.session || {};
    return {isLoading, error, checked, authenticated, recoverySuccessful};
}

export default withRouter(connect(mapStateToProps)(LoginContainer));