import React from 'react';

class Tab extends React.Component {
    handleClick = () => {
        if (this.props.notClickable) {
          return;
        }
        
        this.props.onClick(this.props.index);
    };

    render() {
        return (
            <button
                className={`dashboard-tab__button ${
                    this.props.isActive ? 'dashboard-tab__button-active' : ''
                } ${this.props.notClickable ? 'cursor-text' : ''}`}
                onClick={this.handleClick}
            >
                {this.props.title}
            </button>
        );
    }
}

export default Tab;
