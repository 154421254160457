import React from "react";
import { withRouter } from "react-router-dom";
import { reset } from "redux-form";
import { clearUserProfile } from "../actions/user";
import queryString from "querystring";
import LOGO from "../img/RH_new_Logo.svg";
import DoctorSignUpForm from "../components/forms/DoctorSignUpForm";
import LoadingScreen from "../components/LoadingScreen";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import { useTranslation, withTranslation } from 'react-i18next';
import userService from "../services/userService";

class DoctorSignUpContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      formData: {
        user: {},
      },
      message: null,
      error: null,
      successful: false,
      doctorSpecialties: [],
      lang: this.props.i18n.language,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get('at');
 

    if (!accessToken) {
      this.setError({
        message: "No Access Token found",
        code: 403,
        isLoading: false,
      });
    } else {
      this.service = new ApiService(accessToken);
      this.setState({
        isLoading: false,
        formData: {
          user: {
            accessToken: accessToken,
          },
        },
      });

      Promise.all([
        userService.getSpecialties(this.props.i18n.language === 'ar' ? 'translated' : '')
      ]).then(
        (response) => {
          this.setState({
            isLoading: false,
            doctorSpecialties: response[0].data
          });
        },

        (e) => {
          console.log(e);
        }

      )
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.lang !== this.props.i18n.language) {
        userService
            .getSpecialties(this.props.i18n.language === 'ar' ? 'translated' : '')
            .then((response) => {
                this.setState({
                  doctorSpecialties: response.data
                });
            });

            this.setState({
              lang: this.props.i18n.language
          });

          }
  }

  setError = (error, abort) => {
    this.setState({
      isLoading: false,
      abort,
      error,
    });
  };

  scrollToError = () => {
    this.errorHolder.scrollIntoView({ behaviour: "smooth" });
  };

  onSubmitForm = (values, dispatch) => {
    this.setState({
      isLoading: true,
    });

    this.service.post(ENDPOINTS.doctor.register, values).then(
      (message) => {
        //reset sign up form on successful submission
        dispatch(reset("doctorSignUpForm"));
        dispatch(clearUserProfile());
        this.setState({
          error: null,
          message: message,
          successful: true,
          abort: false,
          isLoading: false,
        });
      },
      (e) => {
        this.setError({
          message: e.message,
          code: e.code,
          isLoading: false,
        });
        this.scrollToError();
      }
    );
  };

  render() {
    const { isLoading, formData, error, abort, successful, doctorSpecialties } =
      this.state;

    let accessTokenError;

    if (error?.message.includes('No Access Token found')) {
      accessTokenError = this.props.t('accessTokenNotFound');
    }

    if (isLoading)
      return (
        <SignUpFormHolder>
          <LoadingScreen />
        </SignUpFormHolder>
      );

    if (!isLoading && abort)
      return (
        <SignUpFormHolder>
          <div
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            <h3>
              An error seems to have occurred. Please try again. If issues
              persist, kindly send a mail to{" "}
              <a
                style={{ color: "white" }}
                href="mailto:doctors@getreliancehealth.com?Subject=Doctor profile completion [from web app]"
              >
                doctors@getreliancehealth.com 
              </a>
              .
            </h3>
          </div>
        </SignUpFormHolder>
      );

    if (!isLoading && successful)
      return (
        <SignUpFormHolder>
        <div
          style={{
            padding: "10px",
            margin: "10px",
            backgroundColor: "#fffffa",
            color: "#111",
          }}
        >
          <div>
            <h3>{this.props.t('congrats.header')}</h3>
            <p>
            {this.props.t('congrats.p1')}
              <br />
              <br />
              {this.props.t('congrats.p2')}
              <br />
              <br />
              {this.props.t('congrats.p3')}
              <br />
              {this.props.t('addressLabel')}{" "}
              <a href="https://maps.google.com/?q=16%20El%20Khartoum%20St,%20Almazah,%20Heliopolis,%20Cairo%20Governorate%204460264,%20Egypt">{this.props.t('congrats.address')}</a>
              <br />
              {this.props.t('emailLabel')}: {" "}
              <a href="mailto:doctors@getreliancehealth.com?Subject=Doctor Enquiry [from web app]">
              doctors@getreliancehealth.com 
              </a>
              <br />
              {this.props.t('telephoneLabel')}:{" "} <a href="tel:17008">17008</a>
              <br />
              {this.props.t('whatsappLabel')}:{" "} <a href="tel:+201119952208"> +20-111-995-2208</a>
              <br />
              {this.props.t('facebookLabel')}:{" "}
              <a href="https://web.facebook.com/Reliancehealthng/?_rdc=1&_rdr">
              @Reliancehealthng 
              </a>
              <br />
              <br />
              Thanks for choosing Reliance Health.
            </p>
          </div>
        </div>
      </SignUpFormHolder>
      );

    return (
      <SignUpFormHolder user={formData.user}>
        {!isLoading && error && (
          <div
            ref={(el) => (this.errorHolder = el)}
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "red",
              color: "white",
              visibility: !isLoading && error ? "visible" : "hidden",
            }}
          >
            <h3>{
              accessTokenError ? accessTokenError : error.message
              }</h3>
          </div>
        )}

        <DoctorSignUpForm
          data={formData.utils}
          user={formData.user}
          onError={this.setError}
          onSubmit={this.onSubmitForm}
          doctorSpecialties={doctorSpecialties}
        />
      </SignUpFormHolder>
    );
  }
}

const DoctorSignUpContainerWithTranslation = withTranslation(['doctor-signup'])(DoctorSignUpContainer);

export default withRouter(DoctorSignUpContainerWithTranslation);

export const SignUpFormHolder = (props) => {

  const {t} = useTranslation(['doctor-signup']);

  return (
  <div className="container">
    <div className="signup">
      <div className="signup__header">
        <img src={LOGO} alt="logo" />
        <p>{t('pageHeader')}</p>
      </div>
      <div className="dashboard-card signup__form">{props.children}</div>
    </div>
  </div>
  )
};
