export const TIP = 'TIP';
export const USER = 'USER';
export const MCHAT = 'MCHAT';
export const SCHAT = 'SCHAT';
export const PROVIDER = 'PROVIDER';
export const UTIL = 'UTILITIES';
export const DISPLAY = 'DISPLAY';
export const PLANNER = 'PLANNER';
export const CONSULTATION = 'CONSULTATION';

export const CATEGORIES = [TIP,USER,MCHAT,SCHAT,PROVIDER];