import React from 'react';
import { useTranslation } from 'react-i18next';

export default function StatsDataCard({ name, data, iconClassName }) {
    const { i18n } = useTranslation(['webapp']);
    return (
        <div
            className={`${'dashboard-card'} ${'stats-data-card'} ${
                i18n.language === 'ar' ? 'arabic-card' : ''
            }`}
        >
            <div className="dashboard-card__text--left-align">
                <p className="dashboard-card__title-text">{name}</p>
                <p className="dashboard-card__data-text">{data}</p>
            </div>
            <i className={`fas ${iconClassName} fa-3x stats-data-icon`}></i>
        </div>
    );
}
