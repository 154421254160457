// create language data for the language selector, english en and arabic ar

export const languages = [
    {
        id: 'ar',
        title: 'العربية',
        flag: 'ae'
    },
    {
        id: 'en',
        title: 'English',
        flag: 'us'
    }
];
