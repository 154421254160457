import React from 'react';

export default function (t, i18n) {
    return [
        {
            selector: '[data-tut="step_settings_tour"]',
            content: () => (
                <div
                    dir={i18n.dir()}
                    style={{
                        marginRight: i18n.dir() === 'rtl' ? '10px' : '0'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <h4
                            style={{
                                color: '#fff',
                                fontSize: '18px',
                                fontWeight: '700',
                                lineHeight: '22px',
                                marginBottom: '12px'
                            }}
                        >
                            {t('tour:my_setting-tour.my_settings')}
                        </h4>
                    </div>
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            marginBottom: '16px'
                        }}
                    >
                        {t('tour:my_setting-tour.my_settings_text')}
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <p
                            style={{
                                color: '#fff',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '17px'
                            }}
                        >
                            {t('tour:general-tour.step1/1')}
                        </p>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <button
                                style={{
                                    background: '#00508E',
                                    padding: '8px 32px',
                                    borderRadius: '5px',
                                    fontSize: '14px',
                                    display: 'block',
                                    cursor: 'pointer',
                                    border: '0',
                                    color: '#fff'
                                }}
                                onClick={() => {
                                    localStorage.setItem('isTourOpen', false);
                                    window.location.href = '/';
                                }}
                            >
                                {t('tour:general-tour.finish')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
            style: {
                padding: '24px 32px',
                minWidth: '407px',
                background: '#003F75'
            }
        }
    ];
}
