import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['ar', 'en'],
        backend: {
            // translation file path
            loadPath: '/locale/i18n/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'ar',
        lng: Cookies.get('i18next') || 'ar',
        debug: false,
        // can have multiple namespaces, in case you want to divide a huge
        // translation into smaller pieces and load them on demand
        ns: ['signup', 'tour', 'webapp', 'modal', 'calendar'],
        detection: {
            order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie'],
            lookupCookie: 'i18next'
        },
        interpolation: {
            espaceValue: false,
            formatSeparator: ','
        }
    });

export default i18n;
