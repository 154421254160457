import React, { useState, useEffect } from 'react';
import PageHeader from './boxes/PageHeader';
import MainContent from './boxes/MainContent';
import PageBody from './boxes/PageBody';
import { getAccountsDashboardUrl } from '../services/utilityService';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import tourSettings from '../components/tours/tourSettings';
import { useTranslation } from 'react-i18next';

const SettingsPage = (props) => {
    const [isTourOpen, setTourOpen] = useState(false);
    const { t, i18n } = useTranslation(['webapp', 'tour']);

    const openTour = () => {
        setTourOpen(true);
    };

    useEffect(() => {
        openTour();

        if (localStorage.getItem('isTourOpen') == null) {
            localStorage.setItem('isTourOpen', true);
            setTourOpen(false);
        } else {
            let isTourOpenSync = localStorage.getItem('isTourOpen');
            setTourOpen(JSON.parse(isTourOpenSync));
        }
    }, []);

    const closeTour = () => {
        setTourOpen(false);
    };

    const disableBody = (target) => disableBodyScroll(target);
    const enableBody = (target) => enableBodyScroll(target);

    return (
        <MainContent>
            <PageHeader title={t('sidebar.settings')} />
            <PageBody>
                <div className="dashboard-card">
                    <ul>
                        {/* <li className="dashboard-list-item">
                      <span>Cycle Planner: &nbsp;&nbsp;
                          <label className="switch ">
                              <input type="checkbox "/>
                              <span className="slider round "/>
                          </label>
                      </span>
                    </li> */}
                        <li className="dashboard-list-item">
                            <span>
                                {t('settings.notification')}: &nbsp;&nbsp;
                                <label className="switch">
                                    <input type="checkbox " />
                                    <span className="slider round " />
                                </label>
                            </span>
                        </li>
                        <li className="dashboard-list-item">
                            <a href="/change-password">{t('settings.change_password')}</a>
                        </li>
                        {/* <li className="dashboard-list-item">
                            <a href={`${getAccountsDashboardUrl()}/welcome?src=enrollee`}>
                                {t('settings.manage_accounts')}
                            </a>
                        </li> */}
                        <li className="dashboard-list-item">
                            <a href="/logout">{t('settings.logout')}</a>
                        </li>
                    </ul>
                </div>
            </PageBody>
            <Tour
                onRequestClose={closeTour}
                steps={tourSettings(t, i18n)}
                isOpen={isTourOpen}
                maskClassName="mask"
                className="helper"
                rounded={12}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
        </MainContent>
    );
};

export default SettingsPage;
