import React from 'react';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RatingSuccessfulPage = (props) => {
  const {t} = useTranslation(['consultDoctor']);
  return(
    <MainContent>
      <PageHeader title={t('ratingSuccessful')}/>
        <PageBody>
          <div>
            <div style={{alignItems: "center"}}>
              <p style={{fontSize:"1.6em"}}>{t('successfullyRated')}</p>
              <br/>
              <br/>
              <div style={{fontSize:"1.4em"}}>
                <div>
                  <Link to="/">{t('backHome')}</Link>
                </div>
              </div>
            </div>
          </div>
        </PageBody>
    </MainContent>
  )
};

export default RatingSuccessfulPage;
