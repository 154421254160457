import React, { Component } from 'react';
import Modal from '../boxes/Modal';
import { useTranslation, withTranslation } from 'react-i18next';

class ProductsModal extends Component {
    render() {
        const { onClose, unassignedProducts, selectUnassignedProduct } = this.props;

        return (
            <Modal title={this.props.t('my_profile.products_list')} onClose={onClose}>
                <div className="unassigned-prod-modal-wrap">
                    {unassignedProducts.length > 0 ? (
                        unassignedProducts.map((product) => (
                            <div
                                key={product.id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{ margin: '1rem 2rem' }}>
                                    <h3 className="dashboard-card__title-text">{product.name}</h3>
                                    <p className="unassigned-prod-modal-wrap__small-text">
                                        {this.props.t('my_profile.slots_available', {
                                            slot: product.available_slots
                                        })}
                                    </p>
                                </div>
                                <button
                                    style={{ marginLeft: '2rem' }}
                                    onClick={() => selectUnassignedProduct(product)}
                                    className="dashboard__primary-btn"
                                >
                                    {this.props.t('my_profile.select')}
                                </button>
                            </div>
                        ))
                    ) : (
                        <p className="unassigned-prod-modal-wrap__small-text">
                            {this.props.t('my_profile.slots_used_up')}
                        </p>
                    )}
                </div>
            </Modal>
        );
    }
}

const ProductsModalWithTranslation = withTranslation(['webapp'])(ProductsModal);
export default ProductsModalWithTranslation;
