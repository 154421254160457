import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


const CallData = (props) => {
  const { i18n } = useTranslation(['webapp']);
  return (
    <React.Fragment>
      <div style={{display: "flex"}}>
        <div style={{margin: "0 1rem 1rem 0", padding: "1rem"}}>
          <i 
            className="fas fa-phone" 
            style={{ cursor: "pointer", fontSize: "20px"}} 
          >
          </i>
        </div>

        <div style={{ fontSize: "1.3rem" }}>
          <p> {props.message}</p>
          <p>{moment(props.created_at).locale(i18n.language).format('HH:mm')}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CallData;