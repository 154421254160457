import React from 'react';
import USER_STUB from '../../img/user_stub.png';
import { useTranslation } from 'react-i18next';

function ProfileItemGroup({ items }) {
    return (
        <div className="profile-item">
            {items.map((item) => (
                <ProfileItem key={item.name} itemName={item.name} value={item.value} />
            ))}
        </div>
    );
}

function ProfileItem({ itemName, value }) {
    return (
        <div className="profile-item-wrapper">
            <p className="profile-item__label">{itemName}</p>
            <p className="profile-item__text">{value}</p>
        </div>
    );
}

export default function DashboardProfileCard({ image, details }) {
    const { t } = useTranslation(['webapp']);

    return (
        <div className="dashboard-card profile-section">
            <img src={image || USER_STUB} className="profile__img" alt={t('my_profile.enrollee')} />
            <div className="profile-detail-wrap">
                {Object.values(details).map((group, i) => (
                    <ProfileItemGroup key={i} items={group} />
                ))}
            </div>
        </div>
    );
}
